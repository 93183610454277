import * as React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import Main from '../components/Main';
import RichMedia from '../components/RichMedia';

interface IndexPageQuery {
  allPost: {
    nodes: {
      title: string,
      fields: {
        slug: string,
      },
    }[],
  },
}

const IndexPage = () => {
  const data = useStaticQuery<IndexPageQuery>(graphql`
  query IndexPageQuery {
    allPost(filter: {fields: {canPublishOnWMP: {eq: true}}}) {
      nodes {
        title
        fields {
          slug
        }
      }
    }
  }
  `);

  return (
    <Main>
      <RichMedia>
        <ul style={{
          marginTop: 'calc(68px - 32px)',
          marginBottom: 'calc(68px - 7px)',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '500px',
        }}>
          {
            data.allPost.nodes.map( (post) => {
              return (
                <li key={post.fields.slug}>
                  <a href={post.fields.slug}>
                    {post.title}
                  </a>
                </li>
              );
            })
          }
        </ul>
      </RichMedia>
    </Main>
  );
};

export default IndexPage;
